<template>
    <base-page>
        <titlu-pagina Titlu="Istoric membri comisie evaluare" @on_add_clicked="show_dialog()" :AdaugaVisible='false' />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        <el-col :span='4'>
                            <el-form-item label='Utilizator' >
                                <el-select class='full-width' v-model='Filters.IdUser' >
                                    <el-option label='Toti' value='-1'></el-option>
                                    <el-option v-for='item in Info.utilizatori' :key="'utilizatori' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span='4'>
                            <el-form-item label='Perioada' >
                                <el-date-picker v-model='Filters.DataStart' type='daterange' value-format='yyyy-MM-dd' format='dd.MM.yyyy' :picker-options="{firstDayOfWeek: 1}"/>
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='Nume' label='Utilizator'></el-table-column>
            <el-table-column prop='Descriere' label='Descriere'></el-table-column>
            <el-table-column label='Data incepere'>
                <template slot-scope='scope'>
                    {{ scope.row.DataStart | momentformat('dd.MM.YYYY') }}
                </template>
            </el-table-column>
            <el-table-column label='Data incetare'>
                <template slot-scope='scope'>
                    {{ scope.row.DataSfarsit | momentformat('dd.MM.YYYY') }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >
                    <!-- <el-tooltip content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip> -->
                    <el-tooltip content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>
                </template>
                </el-table-column>
            </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Istoric-membri-comisie-evaluare-dialog ref='dlg' @save="refresh_info()" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Istoric_membri_comisie_evaluare_dialog from '@/pages/istoric_membri_comisie_evaluare/Istoric_membri_comisie_evaluare_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';
import moment from 'moment';

export default {
    name: "istoric_membri_comisie_evaluare",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Istoric-membri-comisie-evaluare-dialog': Istoric_membri_comisie_evaluare_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { utilizatori: [] },              
            Filters: { IdUser: '-1' , DataStart: '' },              
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            var response            = await this.post("istoric_membri_comisie_evaluare/get_info" );
            this.Info.utilizatori   = response.utilizatori;
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("istoric_membri_comisie_evaluare/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('istoric_membri_comisie_evaluare');
        },
        reset(){
            this.Filters = {  IdUser: '-1' , DataStart: '' };              
            this.refresh_info();
        },
        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("istoric_membri_comisie_evaluare/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
